<template>
    <div class="mining">
        <!-- 移动端头部 -->
        <div class="mining-nav flex">
            <div>{{ $t('home.desc4') }}</div>
            <div class="flex" @click="$router.push('/planet')">{{ $t('home.desc1') }} <img
                    src="../../public/images/planetrighticon.png" alt=""></div>
        </div>
        <div class="mining-con flex">
            <div class="mining-list flex animate__animated animate__bounceInUp" :style="{
                'animation-delay':`${(index+1)*0.1}s`
            }" v-for="(item, index) in list" :key="item.id">
                <!-- <div class="ml-bg" :style="{
                'background-image':`url(${item.eraIcon}`
            }"></div> -->
                <img :src="item.eraIcon" alt="" class="mining-img" />
                <div class="mining-info">
                    <div class="mining-info-price">{{ item.priceAmount }} USDT</div>
                    <div class="mining-info-name flex">
                        <div class="info-name-level">
                            <div>{{ $t('planet.desc24') }}</div>
                            <p>{{ item.eraId }}</p>
                        </div>
                        <div class="info-name-level">
                            <div>{{ $t('planet.desc25') }}</div>
                            <p>{{ item.outCoinFullName }}</p>
                        </div>
                        <div class="info-name-level">
                            <div>{{ $t('planet.desc26') }}</div>
                            <p>{{ $t('planet.desc35', { n1: item.outRate }) }}</p>
                        </div>
                        <div class="info-name-level">
                            <div>{{ $t('planet.desc27') }}</div>
                            <p>{{ item.saleCountLand }}/{{ item.totalLand }}</p>
                        </div>
                    </div>
                    <div class="mining-info-total flex">
                        <!-- <div class="total-num flex">
                            <div @click="handlerMin(item.num, index)"><img src="../../public/images/addnum1.png" alt="" />
                            </div>
                            <input type="tel" v-model="item.num" @change="handlerInput($event, index)"
                                onkeyup="this.value=this.value.replace(/\D/g,'')"
                                onafterpaste="this.value=this.value.replace(/\D/g,'')" />
                            <div @click="handlerAdd(item.num, index)"><img src="../../public/images/addnum.png" alt="" />
                            </div>
                        </div> -->
                        <div class="total-btn" @click="openFun(item)">{{ $t('planet.desc28') }}</div>
                    </div>
                </div>
                <div class="mobile-add flex">
                    <!-- <div class="total-num flex">
                        <div><img src="../../public/images/addnum1.png" alt="" /></div>
                        <input type="text" v-model="item.num" />
                        <div><img src="../../public/images/addnum.png" alt="" /></div>
                    </div> -->
                    <!-- <div class="total-btn" @click="handlerInfo(item)">{{ $t('planet.desc28') }}</div> -->
                    <div class="total-btn" @click="openFun(item)">{{ $t('planet.desc28') }}</div>
                </div>
            </div>
        </div>
        <!-- 购买 -->
        <el-dialog title="提示" v-model="dialogBackset" width="30%" :append-to-body="true" class="modalinfo"
            :modal="true">
            <div class="modalinfo-con">
                <div class="modalinfo-con-title">
                    {{ $t('planet.desc29') }}
                    <img src="../../public/images/asset/Union-yellow.png" alt="" @click="dialogBackset = false" />
                </div>
                <div class="mining-tips" v-html="$t('planet.desc30', { n1: `${info.payTotals} ${info.coinSymbol}` })"></div>
                <div class="mining-projuct flex">
                    <img :src="info.eraIcon" alt="" />
                    <div class="projuct-info">
                        <div>{{ info.eraName }}</div>
                        <p>X{{ info.num }}</p>
                    </div>
                </div>
                <div class="modalinfo-con-address">
                    <div class="address-name">{{ $t('home.desc7') }}</div>
                    <div class="address-input">
                        <input type="password" onKeyUp="value=value.replace(/[\W]/g,'')" v-model="password"
                            :placeholder="$t('planet.desc17')" />
                    </div>
                </div>
                <div class="modalinfo-con-address margintop">
                    <div class="address-name">{{ $t('home.desc8') }}</div>
                    <div class="address-input">
                        <input type="tel" v-model="googleCode" onkeyup="this.value=this.value.replace(/\D/g,'')"
                            onafterpaste="this.value=this.value.replace(/\D/g,'')" :placeholder="$t('planet.desc18')" />
                    </div>
                </div>
                <div class="modalinfo-con-btn flex">
                    <div class="flexcenter" @click="dialogBackset = false">{{ $t('planet.desc31') }}</div>
                    <div class="flexcenter" @click="handlerPay" v-loading="loading">{{ $t('planet.desc32') }}</div>
                </div>
            </div>
        </el-dialog>
        <!-- 成功弹窗 -->
        <div class="modal-success" v-if="showSuccess">
            <div class="buy-success">
                <img src="../../public/images/asset/Union-yellow.png" alt="" class="modal-close"
                    @click="showSuccess = false" />
                <div class="buy-success-img">
                    <img src="../../public/images/successicon.png" alt="" />
                </div>
                <div class="buy-success-name">{{ $t('planet.desc50') }}</div>
                <div class="buy-success-desc">{{ $t('planet.desc51', { n1: info.eraName, n2: info.num }) }}</div>
                <div class="buy-success-btn flexcenter" @click="$router.push('/planet')">{{ $t('planet.desc52') }}</div>
            </div>
        </div>
        <!-- 底部 -->
        <div id="footer">
            <glFooter :act="1" />
        </div>
        <!-- 星球详情 -->
        <starDetails v-if="isStarDetails" :id="starDetailsId" :eraId="eraId" :pageType="pageType" :callback="closeFun"></starDetails>
    </div>
</template> 

<script>
import bus from '@/utils/bus'
import starDetails from "../../componets/starDetails.vue";
import glFooter from '@/componets/footer.vue'
export default {
    components: {
        starDetails,
        glFooter
    },
    data() {
        return {
            pageType: 'buy',
            num: 1,
            dialogBackset: false,
            googleCode: '',
            list: [],
            info: {},
            password: '',
            loading: false,
            showSuccess: false,
            isStarDetails: false,          //详情组件显示隐藏
            starDetailsId: '',
            eraId: '',
        }
    },
    mounted() {
        this.getlist()
    },
    methods: {
        openFun(item) {
            this.eraId = item.eraId
            this.isStarDetails = true
        },
        closeFun() {
            this.isStarDetails = false;
        },
        getlist() {
            this.$post(this.URL.planet.list, {}).then(res => {
                if (res.code == 0) {
                    this.list = res.data;
                    this.list.forEach(e => {
                        e.num = 1;
                        e.sale = parseFloat(e.totalLand) - parseFloat(e.saleCountLand)
                    })
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        handlerPay() {
            if (this.password == '') {
                this.$message.error(this.$t('planet.desc17'));
                return;
            }
            if (this.googleCode == '') {
                this.$message.error(this.$t('planet.desc18'));
                return;
            }
            if (!this.loading) {
                this.loading = true;
                this.$post(this.URL.planet.buy, {

                    eraId: this.info.eraId,
                    num: this.info.num,
                    password: this.common.mdpassword(this.password),
                    googleCode: this.googleCode
                }).then(res => {
                    this.loading = false;
                    if (res.code == 0) {
                        this.showSuccess = true;
                        this.dialogBackset = false;
                        this.getlist()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        handlerInfo(item) {
            if (!this.$store.state.userData.hasSetPassword) {
                this.$message.error(this.$t('home.desc48'))
                bus.emit('hadleropen',1)
                return;
            }
            if (!this.$store.state.userData.hasBindGoogleAuth) {
                this.$message.error(this.$t('home.desc49'))
                bus.emit('hadleropen',2)
                return;
            }
            this.info = item;
            this.info.payTotals = item.num * parseFloat(item.priceAmount);
            this.dialogBackset = true;
            this.password = '';
            this.googleCode = '';
            this.loading = false;
        },
        handlerAdd(num, i) {
            if (num >= this.list[i].sale) {
                this.list[i].num = this.list[i].sale;
            } else {
                this.list[i].num++;
            }
        },
        handlerMin(num, i) {
            if (num < 1) {
                this.list[i].num = 1;
            } else {
                this.list[i].num--;
            }
        },
        handlerInput(e, i) {
        
            if (this.list[i].sale == 0) {
                this.list[i].num = 0
            } else if (this.list[i].num > this.list[i].sale) {
                this.list[i].num = this.list[i].sale
            } else if (this.this[i].num < 1) {
                this.list[i].num = 1
            }
        }
    }
}
</script>

<style lang="less" scoped>
.mining {
    padding: 116px 0 7px;
    width: 1240px;
    margin: 0 auto;
    min-height: 600px;

    .mining-con {
        flex-wrap: wrap;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .mining-nav {
        display: none;
    }

    .mining-list {
        flex: 0 0 613px;
        padding: 14px 0 14px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        margin: 0 0 14px 0;
        position: relative;
        border-radius: 16px;
        background: url('../../public/images/land/bg6.png') no-repeat center;
        background-size: 108% auto;

        &:nth-child(2n) {
            margin-right: 0;
        }
        &:nth-child(2) {background-image: url('../../public/images/land/bg2.png');}
        &:nth-child(3) {background-image: url('../../public/images/land/bg3.png');}
        &:nth-child(4) {background-image: url('../../public/images/land/bg4.png');}
        &:nth-child(5) {background-image: url('../../public/images/land/bg5.png');}
        &:nth-child(6) {background-image: url('../../public/images/land/bg1.png');}
        &:nth-child(7) {background-image: url('../../public/images/land/bg7.png');}

        .mobile-add {
            display: none;
        }

        .mining-img {
            flex: 0 0 250px;
            width: 250px;
            height: 250px;
            margin: 0 15px;
            object-fit: cover;
        }

        .mining-info {
            flex: 1;
            overflow: hidden;

            .mining-info-price {
                font-size: 26px;
                color: #FFE27F;
                font-weight: 500;
            }

            .mining-info-name {
                width: 257px;
                margin: 10px 0 15px;
                padding: 14px 0;
                flex-wrap: wrap;
                background: linear-gradient(180deg, rgba(255, 226, 127, 0.6) -10.71%, rgba(110, 85, 38, 0.6) 84.58%);
                border-radius: 8px;

                .info-name-level {
                    flex: 0 0 50%;
                    margin-bottom: 16px;

                    div {
                        padding: 0 0 0 16px;
                        font-size: 13px;
                        color: #A8A8A8;
                    }

                    p {
                        margin-top: 10px;
                        padding: 0 0 0 16px;
                        font-size: 14px;
                        color: #FFFFFF;
                    }

                    &:nth-last-child(-n+2) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .mining-info-total {
            width: 257px;
            justify-content: space-between;

            .total-num {
                flex: 0 0 95px;
                height: 32px;
                border: 1px solid #FFE27F;
                box-sizing: border-box;

                div {
                    flex: 0 0 30px;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    cursor: pointer;

                    img {
                        width: 10px;
                        height: 10px;
                        margin-top: 9px;
                    }
                }

                input {
                    flex: 0 0 33px;
                    width: 30px;
                    height: 30px;
                    font-size: 13px;
                    color: #FFFFFF;
                    text-align: center;
                    border-left: 1px solid #FFE27F;
                    border-right: 1px solid #FFE27F;
                    box-sizing: border-box;
                }
            }

            .total-btn {
                width: 100%;
                height: 62px;
                background: url('../../public/images/land/buy.png') center no-repeat;
                background-size: 100% 100%;
                text-align: center;
                line-height: 62px;
                font-size: 22px;
                color: #000000;
                cursor: pointer;
            }
        }
    }
}

.modal-success {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000 url('../../public/images/popup-bg.png') no-repeat;
    background-size: 100% 100%;
    z-index: 56;
}

.buy-success {
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 336px;
    padding: 40px 0;
    background: linear-gradient(180deg, rgba(255, 226, 127, 0.3) -10.71%, rgba(110, 85, 38, 0.3) 84.58%);
    border-radius: 16px;
    z-index: 56;
    text-align: center;
    transform: translate(-50%, -50%);

    .modal-close {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    .buy-success-img {
        img {
            width: 64px;
            height: 64px;
        }
    }

    .buy-success-name {
        padding: 40px 0 24px;
        font-size: 24px;
        color: #FFE27F;
    }

    .buy-success-desc {
        padding: 0 45px 24px;
        font-size: 16px;
        color: #FFE27F;
        line-height: 28px;
    }

    .buy-success-btn {
        width: 221px;
        height: 63px;
        margin: 0 auto;
        background: url('../../public/images/planetbtnbg.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        cursor: pointer;
    }
}

#footer {
    display: none;
}

@media (max-width:1200px) {
    #footer {
        display: block;
    }

    .mining {
        padding: 68px 15px 20px;
        width: 700px;

        .mining-nav {
            width: 100%;
            padding: 24px 0;
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;

            div {
                &:last-child {
                    font-size: 13px;

                    img {
                        width: 5.5px;
                        height: 8.5px;
                        margin: 7px 0 0 4px;
                    }
                }
            }
        }

        .mining-list {
            flex: 0 0 100%;
            // height: 222px;
            margin-right: 0;
            padding: 16px 0;
            flex-wrap: wrap;
            background: url('../../public/images/land/h5/bg1.png') no-repeat center;
            background-size: 136% auto;
            &:nth-child(2) {background-image: url('../../public/images/land/h5/bg2.png');}
            &:nth-child(3) {background-image: url('../../public/images/land/h5/bg3.png');}
            &:nth-child(4) {background-image: url('../../public/images/land/h5/bg4.png');}
            &:nth-child(5) {background-image: url('../../public/images/land/h5/bg5.png');}
            &:nth-child(6) {background-image: url('../../public/images/land/h5/bg6.png');}
            &:nth-child(7) {background-image: url('../../public/images/land/h5/bg7.png');}

            .mining-img {
                flex: 0 0 168px;
                width: 168px;
                height: 168px;
                margin: 15px 0 0 0;
            }

            .mining-info {
                flex: 1;
                overflow: hidden;
                margin-right: 10px;

                .mining-info-price {
                    margin-top: 0;
                    font-size: 24px;
                    color: #fff;
                }

                .mining-info-name {
                    margin: 2px 0;
                    width: 100%;
                    background: none;

                    .info-name-level {
                        margin-bottom: 16px;
                        div,p {padding: 0;}
                    }
                }

                .mining-info-total {
                    display: none;
                }
            }

            .mobile-add {
                flex: 0 0 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 0;

                .total-num {
                    flex: 0 0 95px;
                    height: 32px;
                    margin: 3px 56px 0 15px;
                    border: 1px solid #FFE27F;
                    box-sizing: border-box;

                    div {
                        flex: 0 0 30px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        cursor: pointer;

                        img {
                            width: 10px;
                            height: 10px;
                            margin-top: 9px;
                        }
                    }

                    input {
                        flex: 0 0 33px;
                        width: 30px;
                        height: 30px;
                        font-size: 13px;
                        color: #FFFFFF;
                        text-align: center;
                        border-left: 1px solid #FFE27F;
                        border-right: 1px solid #FFE27F;
                        box-sizing: border-box;
                    }
                }

                .total-btn {
                    flex: 0 0 145px;
                    height: 37px;
                    margin-right: 30px;
                    background: url('../../public/images/planetbtnbg-yellow.png') center no-repeat;
                    background-size: 100% 100%;
                    text-align: center;
                    line-height: 36px;
                    font-size: 16px;
                    color: #000000;
                    cursor: pointer;
                }
            }
        }
    }

}

@media (max-width:767px) {
    .mining {
        width: auto;
    }

    .buy-success {
        width: 90%;
    }
}

@media (max-width:360px) {
    .mining {

        .mining-list {
            .mining-img {
                flex: 0 0 90px;
                width: 90px;
                height: 90px;
                margin-top: 24px;
            }

            .mobile-add {
                .total-num {
                    margin-right: 10px;
                }
            }
        }

    }
}</style>